import { FC, useEffect, useRef, useState } from 'react';
import { interval, Subject, take, takeUntil } from 'rxjs';
import localStorageService from '../../../../../services/localStorageService';
// components
import { BorderLinearProgress } from './ProgressBar';
// assets
import styles from './loadingResult.module.scss';
import { StepsSlidesAnimations } from './StepsSlidesAnimations';
import { SwiperRef, useSwiper, useSwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { useQuizGPT } from '../../quizGPT.hooks';
import { Error } from '../../../error/Error';
import { useNavigate } from 'react-router-dom';
import { ReligionyfyTypes } from '../../../../../models/QuizGptGenerators';


export const LoadingResults: FC = () => {
  const navigate = useNavigate();
  const [stepsCounter, setStepsCounter] = useState(1);
  const [progresBarCounter, setProgresBarCounter] = useState(0);
  const [finish, setFinish] = useState(false);
  const { data, hasLoaded, payload, error, selectedQuizGen } = useQuizGPT();

  let $steps = interval(payload.model === 'gpt-3.5-turbo' ? 5_900 : 11_200);
  let $progresBar = interval(payload.model === 'gpt-3.5-turbo' ? 680 : 1350);
  const $destroy = new Subject<void>();

  const { t } = useTranslation();
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const swiperRef = useRef<SwiperRef>(null);
  const lastProgress = [95, 96, 97, 98, 99];
  const lastProgressRandom = lastProgress[Math.floor(Math.random() * lastProgress.length)];

  if (selectedQuizGen === ReligionyfyTypes.LESSON) {
    $steps = interval(5_850);
    $progresBar = interval(678);
  }

  const finishLoading = () => {
    setProgresBarCounter(100);
    setFinish(true);
  };

  useEffect(() => {
    if (finish) {
      setTimeout(() => {
        $destroy?.complete();
        $destroy?.unsubscribe();
        const activeFormData = localStorageService.get<boolean | null>('activateFormModal');
        if (activeFormData === null) {
          localStorageService.save('activateFormModal', true);
        }
        if (selectedQuizGen === ReligionyfyTypes.QUIZ) {
          // window.location.href = `${data.url}&topic=${quizTopic}&language=${i18n.language}`;
          const url = new URL(data?.url ?? '');
          const quizId = url.pathname.split('/').at(-1);
          navigate(`/play-quiz/${quizId}`);
        }
        // selectedQuizGen === ReligionyfyTypes.CHAT ||
        else if (selectedQuizGen === ReligionyfyTypes.LESSON) {
          try {
            $destroy?.next();
            $destroy?.complete();
          }
          catch (err) {
            console.log((err as Error).message);
          }
          navigate(`lessons/${data.quizId}`);  // story-telling
        }
      }, 500);
    }
  }, [finish]);

  useEffect(() => {
    if (swiperSlide?.isActive) {
      $steps?.pipe(take(5), takeUntil($destroy))?.subscribe(val => {
        if (hasLoaded) {
          setStepsCounter(6);
        }
        else {
          setStepsCounter(val + 2);
        }
        swiperRef.current?.swiper?.slideNext();
      });
      $progresBar?.pipe(take(100), takeUntil($destroy))?.subscribe(() => {
        const numbers = [0, 1, 1, 1, 2, 2, 3, 4];
        const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];
        setProgresBarCounter((prev) => {
          if (prev === 100) return 100;
          const newProgress = prev + randomNumber;
          if (newProgress > lastProgressRandom) {
            return lastProgressRandom;
          }
          return newProgress;
        });
      });
    }

    return () => {
      try {
        $destroy?.next();
        $destroy?.complete();
      } catch (error) {
        console.log(error);
      }
    };
  }, [swiper.activeIndex]);


  useEffect(() => {
    if (hasLoaded) {
      finishLoading();
    }
  }, [hasLoaded]);

  if (error) {
    return <Error />;
  }
  return (
    <div className={styles.resultWrapper}>

      <section className={styles.resultContainer}>

        <StepsSlidesAnimations swiperRef={swiperRef} />

        <div className={styles.progresBar}>
          <BorderLinearProgress
            variant="determinate"
            value={progresBarCounter}
          />
          <div className={styles.steps}>
            <p>{t('steps', { step: stepsCounter })}</p>
            <p>{progresBarCounter}%</p>
          </div>
        </div>
      </section>
    </div>
  );
};
