import { ButtonHTMLAttributes, ClassAttributes, forwardRef, HTMLAttributes } from 'react';
import styles from './button.module.scss';


type HTMLProps<T> = ClassAttributes<T> & HTMLAttributes<T> & ButtonHTMLAttributes<T>;


export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  disabled?: boolean;
  selected?: boolean;
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const darkMode = false;
  return (
    <button
      {...props}
      disabled={props.disabled}
      ref={ref}
      style={{
        color: !props.color ? 'var(--base-content)' : '#fff',
        boxShadow: `0px 0px 1px 1px ${!props.color ? 'var(--gray-250-opac)' : 'var(--base-shadow)'}`,
        cursor: props.disabled ? 'auto' : 'pointer',
        ...props.style
      }}
      className={
        `${styles.button}
        ${props?.color ?
          styles[props.color as string] : darkMode ? styles.defaultDark : styles.default
        }
        ${!styles[props.color as string] && props.disabled && styles.defaultDisable}
        ${props?.className}`
      }
    >
      {props.children}
    </button>
  );
});

Button.displayName = 'Button';
