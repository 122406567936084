import { FC, MouseEvent, useRef } from 'react';
// helpers
import { createPortal } from 'react-dom';
// components
import { CSSTransition } from 'react-transition-group';
// assets
import styles from './loginForm.module.scss';
import banner from '../../../assets/settings/banner_login.png';
import google from '../../../assets/google.png';
import facebook from '../../../assets/religionify/facebook.png';
import { BackDrop } from '../../backDrop/BackDrop';
import { useQuizGPT } from '../../../app/pages/quizGPT/quizGPT.hooks';
import { useTranslation } from 'react-i18next';
import { auth } from '../../../firebase';
import { AuthProvider, FacebookAuthProvider, GoogleAuthProvider, User, signInWithPopup } from 'firebase/auth';
import quizGPTService from '../../../services/quizGPTService';
import localStorageService from '../../../services/localStorageService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';


export const LoginForm: FC = () => {
  const transitionRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();
  const { setUser, setShowLoginModal, showLoginModal, setAccessToken } = useQuizGPT();

  const loginApp = async (user: User) => {
    const token = await user.getIdToken();
    const name = user.displayName ? user.displayName.split(' ')[0] : '';
    const lastName = user.displayName ? user.displayName.split(' ')[1] : '';
    const payload = {
      uid: user.uid,
      email: user.email,
      firstName: name,
      lastName: lastName,
      token: token,
      app: 'quizGPT',
      // language: i18n.language
    };
    // const response =
    await quizGPTService.login(payload);
    // if (response) {
    //   localStorage.setItem('accessToken', response.data.token);
    // }
  };

  const handleLogin = async (provider: AuthProvider) => {
    try {
      const { user } = await signInWithPopup(auth, provider);
      if (user) {
        setUser(user);
        setShowLoginModal(false);
        const accessToken = await user.getIdToken();
        const userExists: boolean = await quizGPTService.userExists(user.uid, accessToken);
        if (!userExists) {
          await loginApp(user);
        }
        localStorageService.save('accessToken', accessToken);
        localStorageService.save('userID', user.uid);
        setAccessToken(accessToken);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginGoogle = async (e: MouseEvent) => {
    e.stopPropagation();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ display: 'popup', prompt: 'select_account' });
    handleLogin(provider);
  };

  const loginFacebook = async (e: MouseEvent) => {
    e.stopPropagation();
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({
      'display': 'popup'
    });
    handleLogin(provider);
  };

  const Form: FC = () => (
    <div className={styles.form} onClick={(e) => e.stopPropagation()}>
      <button
        type="button"
        onClick={() => setShowLoginModal(false)}
        className={styles.closeButton}
        aria-label="close"
      >
        <FontAwesomeIcon
          className={styles.closeIcon}
          size="lg"
          fontWeight={900}
          icon={faXmark}
        />
      </button>

      <figure className={styles.imgContainer}>
        <img src={banner} alt="img" />
      </figure>

      <div className={styles.content}>
        <div className={styles.header}>
          <h3 className={styles.heading}>
            {t('loginModal.title')}
          </h3>
          <p>
            {t('loginModal.subtitle')}
          </p>
        </div>
        <div className={styles.fieldGroup}>
          <button type="button" className={styles.facebook} onClick={loginFacebook}>
            <img src={facebook} alt="world icon" />
            <p>Facebook</p>
          </button>
          <button type="button" className={styles.google} onClick={loginGoogle}>
            <img src={google} alt="sound icon" />
            <p>Google</p>
          </button>
        </div>
        <div className={styles.footer}>
          <p>
            {t('loginModal.footer')}
            <a rel="noreferrer" target="_blank" href={t('loginModal.termsUrl') as string}>
              {t('loginModal.terms')}</a>
            <span> {t('loginModal.and')}</span>

            <a rel="noreferrer" target="_blank" href={t('loginModal.conditionsUrl') as string}>{t('loginModal.conditions')}</a>.
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <CSSTransition
      nodeRef={transitionRef}
      in={showLoginModal}
      unmountOnExit={true}
      timeout={{ enter: 200, exit: 180, appear: 200 }}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive
      }}>
      <>
        {createPortal(
          <BackDrop onClick={() => setShowLoginModal(false)}>
            <div className={styles.container}>
              <Form />
            </div>
          </BackDrop>,
          document.querySelector('#portal') as HTMLElement
        )}
      </>
    </CSSTransition>
  );
};
