import { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useQuizGPT } from '../../quizGPT.hooks';
// components
import { Button } from '../../../../../components';
import { Formik, Form, Field } from 'formik';
import { InputTypes } from '../interfaces';
import { ReligionyfyTypes } from '../../../../../models/QuizGptGenerators';
import { useSwiper } from 'swiper/react';
import styles from './quizGenForm.module.scss';


const quizGeneratorSchemma = Yup.object().shape({
  topic: Yup.string()
    .min(1, 'Too Short!')
    .required('Required'),
});

interface Props {
  inputType: InputTypes,
  quizGenType: ReligionyfyTypes
}

export const QuizGenForm: FC<Props> = ({ inputType, quizGenType }) => {
  const {
    quizGptSettings,
    generateChat,
    generateOnlyQuiz,
    generateLesson,
    outerSwiper,
    setQuizTopic,
    accessToken,
    setShowLoginModal,
    setShowSnapBookSlide,
  }
    = useQuizGPT();
  const innerSwiper = useSwiper();
  const { t } = useTranslation();
  const i18nTexts = {
    placeholder: t(`${quizGenType}.input.placeholder`),
    label: t(`${quizGenType}.input.label`),
    button: t(`${quizGenType}.button`)
  };

  const handleInputsFocus = (): void => {
    const input = document.querySelector('input[name="topic"]') as HTMLInputElement;
    input?.focus();
  };

  useEffect(() => {
    innerSwiper?.on('transitionEnd', handleInputsFocus);
    return () => innerSwiper?.off('transitionEnd', handleInputsFocus);
  }, []);

  return (
    <Formik
      initialValues={{ topic: '' }}
      validationSchema={quizGeneratorSchemma}
      onSubmit={async (values, actions) => {
        if (!accessToken) {
          return setShowLoginModal(true);
        }
        if (quizGenType === ReligionyfyTypes.CHAT) {
          generateChat({
            topic: values.topic,
            emojis: true,
            level: 'intermediate',
            language: quizGptSettings.language === 'es' ? 'Spanish' : 'English',
          });
        }
        if (quizGenType === ReligionyfyTypes.LESSON) {
          generateLesson({
            topic: values.topic,
            difficulty: quizGptSettings.quizzesDifficulty,
            language: quizGptSettings.language === 'es' ? 'Spanish' : 'English',
            educator: quizGptSettings.educator,
            style: quizGptSettings.style,
            emojis: quizGptSettings.emoji,
            media: quizGptSettings.media,
          });
        }
        else if (quizGenType === ReligionyfyTypes.QUIZ) {
          generateOnlyQuiz({
            topic: values.topic,
            difficulty: quizGptSettings.quizzesDifficulty,
            language: quizGptSettings.language === 'es' ? 'Spanish' : 'English',
            media: quizGptSettings.media,
            model: quizGptSettings.model
          }, inputType);
        }
        if (inputType === 'topic') {
          setQuizTopic(values.topic);
        }
        actions.setSubmitting(false);
        setShowSnapBookSlide(false);
        setTimeout(() => {
          outerSwiper?.slideNext();
          actions.resetForm();
        }, 160);
      }}
    >
      {() => (
        <Form className={styles.form}>
          <ul>
            <li className={styles.inputGroup}>

              <label htmlFor="topic">
                {i18nTexts?.label}
              </label>

              <span className={styles.field}>
                {inputType === 'topic' ? (
                  <Field
                    placeholder={i18nTexts?.placeholder}
                    name="topic"
                    id="topic"
                    type="text" />
                ) : (
                  <Field
                    as="textarea"
                    placeholder={t('quizOnlyQuizForm.text.placeholder') as string}
                    name="topic"
                    id="topic"
                    type="text" />
                )}
              </span>
            </li>

            <span className={styles.button}>
              <Button
                type="submit"
                color="secondary"
                onMouseDown={(event) => { event.preventDefault(); }}
                style={{ width: '100%' }}
              >
                {i18nTexts?.button}
              </Button>
            </span>
          </ul>
        </Form>
      )}
    </Formik>
  );
};
