import { createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { QuizGPT } from './pages/quizGPT/QuizGPT';
import { Error } from './pages/error/Error';
import { Lesson } from './pages/lesson/lesson';
import { QuizGPTProvider } from './pages/quizGPT/quizGPT.provider';
import { PlayReligionifyQuiz } from './pages/playQuiz/PlayQuiz';


export const router = createBrowserRouter([
  {
    path: '',
    element: <App />,

    errorElement: <Error />,

    children: [
      {
        path: '',
        element: <QuizGPT />,
      },
      {
        path: 'lessons/:quizId',
        element: <Lesson />,
      }
    ]
  },
  {
    path: 'integration/lessons/:quizId',
    element: (
      <QuizGPTProvider>
        <Lesson goBack={false} playQuiz={false} />
      </QuizGPTProvider>
    )
  },
  {
    path: 'play-quiz/:quizId',
    element: (
      <QuizGPTProvider >
        <PlayReligionifyQuiz />
      </QuizGPTProvider>
    )
  }
]);
