import axios from 'axios';
import { baseurl } from '../vars';
import { CourseI, LeadsRes, LoginRes, QuizGPTLessonPlusQuizRes, QuizGPTQuizOnlyRes } from '../models/QuizGTPResponse';
import { QuizGPTCourseReq, QuizGPTLessonPlusQuizReq, QuizGPTQuizOnlyReq } from '../models/QuizGPTRequest';
import { CreateUser } from '../models/User';
import { UserInfo } from 'firebase/auth';
import { Categories } from '../models/religionify/Categories';


class QuizGPTService {

  private http = axios.create({
    baseURL: baseurl,
    timeout: 60_000 * 4,
  });

  private setHeader() {
    this.http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
  }

  public login(payload: any) {
    this.setHeader();
    return this.http.post<LoginRes>('/auth/register', payload);
  }

  public createUser(payload: CreateUser) {
    this.setHeader();
    return this.http.post<UserInfo>('/users', payload);
  }

  async userExists(uid: string, accessToken: string): Promise<boolean> {
    try {
      const res = await this.http.get<UserInfo>(`/users/${uid}`, { headers: { 'Authorization': `Bearer ${accessToken}` } });
      if (res.status === 200) {
        console.log('user exists', res);
        return true;
      }
      return false;
    }
    catch {
      return false;
    }
  }

  public generateQuickQuiz(payload: QuizGPTQuizOnlyReq) {
    this.setHeader();
    return this.http.post<QuizGPTQuizOnlyRes>('/generate', payload);
  }

  public generateLesson(payload: QuizGPTLessonPlusQuizReq) {
    this.setHeader();
    return this.http.post<QuizGPTLessonPlusQuizRes>('/lessons/generate', payload);
  }

  public getLessonById(quizId: number) {
    return this.http.get<QuizGPTLessonPlusQuizRes>(`/lessons/${quizId}`);
  }

  public generateCourse(payload: QuizGPTCourseReq) {
    this.setHeader();
    return this.http.post<CourseI>('/courses/generate', payload);
  }

  public getCourseById(id: number) {
    return this.http.get<QuizGPTLessonPlusQuizRes>(`/categories/${id}`);
  }

  public getCategories() {
    this.setHeader();
    return this.http.get<CourseI[]>('/categories');
  }

  public generateTextBasedQuiz(payload: QuizGPTQuizOnlyReq) {
    const fixedPayload = {
      text: payload.topic,
      language: payload.language,
      difficulty: payload.difficulty,
    };
    this.setHeader();
    return this.http.post<QuizGPTQuizOnlyRes>('/generate/text', fixedPayload);
  }

  public getLeads() {
    return this.http.get<LeadsRes>('/leads/forms/demo');
  }

  public registerLeads(payload: any) {
    return this.http.post('/leads', payload);
  }

  public getAppContent() {
    return this.http.get<Categories>('/app-content');
  }
}


export default new QuizGPTService();
