import { FC, MouseEvent, useReducer } from 'react';
import styles from './options.module.scss';
import { useTranslation } from 'react-i18next';
import login from '../../../assets/sidebar/login.png';
import start from '../../../assets/sidebar/star_8_2.png';
import yellowHeart from '../../../assets/sidebar/heart_yellow.png';
import setting from '../../../assets/sidebar/setting.png';
import { useQuizGPT } from '../../../app/pages/quizGPT/quizGPT.hooks';
import { auth } from '../../../firebase';
import localStorageService from '../../../services/localStorageService';
import { SupportModal } from './supportModal/SupportModal';
import { FeedbackModal } from './feedbackModal/FeedbackModal';


export const Options: FC = () => {
  const { t } = useTranslation();
  const { setShowSettingModal, setShowLoginModal, user, setUser } = useQuizGPT();
  const [showSupportModal, setShowSupportModal] = useReducer(prev => !prev, false);
  const [showFeedbackModal, setShowFeedbackModal] = useReducer(prev => !prev, false);

  const OptionObj = ({ icon, text, action, textColor }: { icon: any, text: string, textColor?: string, action?: (e: MouseEvent<HTMLDivElement>) => void }) => {
    return (
      <div className={styles.option} onClick={action}>
        <img src={icon}></img>
        <p style={{ color: textColor }}>{text}</p>
      </div>
    );
  };

  function openFeedback(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setShowFeedbackModal();
  }

  function showSettingModal(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setShowSettingModal(true);
  }

  function showLoginModal(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    setShowLoginModal(true);
  }

  function logOut(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    auth.signOut()
      .then(() => {
        setUser(null);
        setShowLoginModal(true);
        localStorageService.delete('accessToken');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div className={styles.options}>
        <OptionObj
          icon={login}
          text={user ? t('sidebar.options.logOut') : t('sidebar.options.login')}
          action={user ? logOut : showLoginModal}
        />
        <OptionObj
          icon={start}
          text={t('sidebar.options.feedback')}
          action={openFeedback}
        />
        <OptionObj
          icon={yellowHeart}
          text={t('sidebar.options.support')}
          textColor="#fed54e"
          action={setShowSupportModal}
        />
        <OptionObj
          icon={setting}
          text={t('sidebar.options.settings')}
          action={showSettingModal}
        />
      </div>

      <SupportModal
        isOpen={showSupportModal}
        onToggle={setShowSupportModal}
      />

      <FeedbackModal
        isOpen={showFeedbackModal}
        onToggle={setShowFeedbackModal}
      />
    </>
  );
};
