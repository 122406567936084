import { useMutation } from '@tanstack/react-query';
import { ISentEmail } from '../../models/Email';
import emailService from '../../services/emailService';


export const useMutationSentEmail = () => {
  return (
    useMutation({
      mutationFn: async (payload: ISentEmail) => {
        const res = await emailService.sentEmail(payload);
        if (res.status === 200 || res.status === 201) {
          return res.data;
        }
        throw new Error('Something went wrong');
      }
    })
  );
};
