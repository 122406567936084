import styles from './star.module.scss';
import starIconOff from '../../../../../assets/stars/lightmode_star_off_2.png';
import starIconOn from '../../../../../assets/stars/lightmode_star_on_1.png';
// import starIconOffDark from '../../../../../assets/stars/darkmode_star_on_1.png';
import { FC, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { ISentEmail } from '../../../../../models/Email';


interface StarProps {
  isOn: boolean;
  onClick: () => void;
}
const Star: FC<StarProps> = ({ isOn, onClick }) => {
  const starIcon = isOn ? starIconOn : starIconOff;
  return (
    <div>
      <img
        onClick={onClick}
        className={styles.star}
        src={starIcon}
        alt="star"
      />
    </div>
  );
};

export const FiveStars: FC = () => {
  const [stars, setStars] = useState(0);
  const { setFieldValue } = useFormikContext<ISentEmail>();

  return (
    <div className={styles.stars}>
      <Field
        type="number"
        name="rating"
        id="rating"
        style={{ display: 'none' }}
      />

      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          isOn={star <= stars}
          onClick={() => {
            setStars(star);
            setFieldValue('rating', star);
          }}
        />
      ))}
    </div>
  );
};
