import { FC, ReactNode, useRef } from 'react';
import fromTop from './transitionWrapper.module.scss';
import fadeStyles from './fadeStyles.module.scss';
import { CSSTransition } from 'react-transition-group';
import backdropStyles from './navbar.module.scss';



interface Props {
  type?: 'fade' | 'fromTop'
  toggle: boolean,
  children: ReactNode,
  onClose?: () => void
}
export const TransitionWrapper: FC<Props> = ({ toggle, children, type = 'fromTop', onClose }) => {
  const nodeRef = useRef(null);
  const styles = type === 'fromTop' ? fromTop : fadeStyles;

  return (
    <>
      {toggle &&
        <div className={backdropStyles.backrDrop} />
      }
      <CSSTransition
        nodeRef={nodeRef}
        in={toggle}
        unmountOnExit
        timeout={300}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exit: styles.exit,
          exitActive: styles.exitActive,
        }}>
        <div
          onClick={onClose}
          ref={nodeRef}
        >
          {children}
        </div>
      </CSSTransition>
    </>
  );
};
