import { FC } from 'react';
import { PlayQuiz } from '@quizify/quiz-library';
import { useNavigate, useParams } from 'react-router-dom';
// import localStorageService from '../../../services/localStorageService';
import { useTranslation } from 'react-i18next';
// import localStorageService from '../../../services/localStorageService';


export const PlayReligionifyQuiz: FC = () => {
  // const [accessToken, setAccessToken] = useState('');
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  // useEffect(() => {
  //   const accessToken = localStorageService.get<string>('accessToken') ?? '';
  //   setAccessToken(accessToken);
  // }, []);

  // if (!accessToken) {
  //   return null;
  // }
  return (
    <PlayQuiz
      // authToken={accessToken}
      // authToken="eyJhbGciOiJSUzI1NiIsImtpZCI6IjExYzhiMmRmNGM1NTlkMjhjOWRlNWQ0MTAxNDFiMzBkOWUyYmNlM2IiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ2VzYXIgUml2ZXJhIE1pcmFuZGEiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jSUFhNUZRamVOYlhlTW5pWHc0WHJsdXNtUTVYNFEwaTZObk9ma1VQLTlkVW1JPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3ZlcnRpY2FsLWNpcnJ1cy00MTQzMTIiLCJhdWQiOiJ2ZXJ0aWNhbC1jaXJydXMtNDE0MzEyIiwiYXV0aF90aW1lIjoxNzI0NTk1NjYxLCJ1c2VyX2lkIjoiQzNGS3VZc0M4Vll2QUFVbmVnV01qN1ZHYkw0MiIsInN1YiI6IkMzRkt1WXNDOFZZdkFBVW5lZ1dNajdWR2JMNDIiLCJpYXQiOjE3MjQ1OTU2NjEsImV4cCI6MTcyNDU5OTI2MSwiZW1haWwiOiJyaXZlcmFtaXJhbmRhY0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwNzk2NDU2OTM5MDExOTU1ODM2MiJdLCJlbWFpbCI6WyJyaXZlcmFtaXJhbmRhY0BnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.J_2CfPFIKZ7hRO8Yb6SM-decgC5X_5CYJWiFCLMLQRYKHu3YYOpMOSg4DitkTVNQvCZhqVcb2Q-imnGdzziOM6bofCEzEyIFQooTJQEJ2GpTX1b6U-2y9p-MipZrjnJ5e7WJnKFOvXIFdPsSY8kIC4j9Zammo5OzvcoFxJ5a4nJ_yHxGpMIcae24RMuisEcC1rGjeH9lGQyohxnXgMj3R0TIA4uvBvjaeiHNo0Wcyw7RcwBc4EEiLuozaBWnXTnUwesJDyCqKQJX_UYO1N24IB_27Xtxdnc_eOwYT9dHxekUCDxl2tAOtwWpU35W1MlnSwwh7dgyR4auGxSNYcUJHg"
      authToken="eyJhbGciOiJSUzI1NiIsImtpZCI6ImViYzIwNzkzNTQ1NzExODNkNzFjZWJlZDI5YzU1YmVmMjdhZDJjY2IiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQ2VzYXIgUml2ZXJhIE1pcmFuZGEiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFjSFR0YzlPWmlRZ0lSaC16eHdONFBUTTUycHJYQks2V0pCVXlnOURsS2dJMmRQZTZRPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2xhcm51LTI5OTgyMyIsImF1ZCI6Imxhcm51LTI5OTgyMyIsImF1dGhfdGltZSI6MTcxMDYzNjYxOSwidXNlcl9pZCI6Imp0UmdkQjVHMEZYbzFnQUtWNEpqWWJyRVZsdTEiLCJzdWIiOiJqdFJnZEI1RzBGWG8xZ0FLVjRKallickVWbHUxIiwiaWF0IjoxNzEwNjM2NjE5LCJleHAiOjE3MTA2NDAyMTksImVtYWlsIjoicml2ZXJhbWlyYW5kYWNAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMDc5NjQ1NjkzOTAxMTk1NTgzNjIiXSwiZW1haWwiOlsicml2ZXJhbWlyYW5kYWNAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.UOaA5ewKSqi7hmlXB4WzGT4aCnqmeXOqSboX9NJZ03dUzpziTXQuHoMNcwtCk0-DPwm6AnLDNTiKJ3pdBv8mguiivujQDIt-6qqVWO6zlWE7LgcGOzb7aKYW-9yfirgwxOHWrajkfCTlcb-ABFKaUissJEasq7_vYk0e3lXLIgjTvj8vzhlNuRfcKwj7vn5fym53n9a9tUAVRwrLWVrwhNKMSSrZM0X1h2MnvmyrkhwgEUoB2NQ7H6FgNfmeWCkstMf58TbQDQFSy522kE-xvcwzQb6stMpyYBf3YoeAOAdQfcvcrjwHyPHK5fse2iyMxjVdYAmkpzgECLYWHwAQUA"
      isExam={true}
      lang={i18n?.language}
      examnId={Number(params?.quizId)}
      // examnId={21678}
      onGoBack={() => navigate('/')}
    />
  );
};
