import { FC, useEffect, useRef } from 'react';
import styles from './quizGPTGenerators.module.scss';
import { QuizGenForm } from '../quizGenForm/QuizGenForm';
// swiper
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './swiperWrapper.css';
import { useQuizGPT } from '../../quizGPT.hooks';
import { EffectFade } from 'swiper';
import 'swiper/css/effect-fade';
import { ReligionyfyTypes } from '../../../../../models/QuizGptGenerators';


export const QuizGPTGenerators: FC = () => {
  const outersSwiperRef = useRef<SwiperRef>(null);
  const { selectedQuizGen } = useQuizGPT();

  useEffect(() => {
    if (selectedQuizGen === ReligionyfyTypes.CHAT) {
      outersSwiperRef?.current?.swiper.slideTo(0);
    }
    if (selectedQuizGen === ReligionyfyTypes.LESSON) {
      outersSwiperRef?.current?.swiper.slideTo(1);
    }
    if (selectedQuizGen === ReligionyfyTypes.QUIZ) {
      outersSwiperRef?.current?.swiper.slideTo(2);
    }
  }, [selectedQuizGen]);

  return (
    <div className={styles.container}>
      <div className={styles.quizFormsContainer}>
        <Swiper
          autoHeight={true}
          ref={outersSwiperRef}
          allowTouchMove={true}
          speed={400}
          style={{ paddingTop: '1rem' }}
          effect="fade"
          modules={[EffectFade]}
        >
          <SwiperSlide>
            <QuizGenForm
              quizGenType={ReligionyfyTypes.CHAT}
              inputType="topic"
            />
          </SwiperSlide>

          <SwiperSlide>
            <QuizGenForm
              quizGenType={ReligionyfyTypes.LESSON}
              inputType="topic"
            />
          </SwiperSlide>

          <SwiperSlide style={{ position: 'relative' }}>
            <QuizGenForm
              quizGenType={ReligionyfyTypes.QUIZ}
              inputType="topic"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
