import axios from 'axios';
import { emailReceiver, sentEmailUrl } from '../vars';
import { ISentEmail } from '../models/Email';


export class EmailService {
  private emailReceiver: string = emailReceiver ?? '';

  http = axios.create({
    baseURL: sentEmailUrl,
    timeout: 60_000 * 4,
  });

  sentEmail(payload: ISentEmail) {
    return this.http.post('riveramirandac@gmail.com', payload);
  }
}

export default new EmailService();
