import { useState, useEffect, FC } from 'react';
import styles from './textAnimation.module.scss';

type TextAnimationProps = {
  phrases: string[],
  typingSpeed: number,
};

const TextAnimation: FC<TextAnimationProps> = ({ phrases, typingSpeed }) => {
  const [text, setText] = useState('');
  const [changePhrase, setChangePhrase] = useState(1);
  const [phraseId, setPhraseId] = useState(0); // iterator
  const [writedText, setWritedText] = useState<string[]>([]);
  const [stop, setStop] = useState(false);

  const onPhraseComplete = () => {
    if (phraseId === phrases.length - 1) {
      setStop(true);
      return;
    }
    setChangePhrase(changePhrase + 1);
    setPhraseId(phraseId + 1);
    setWritedText((prev) => [...prev, phrases[phraseId]]);
  };

  useEffect(() => {
    setWritedText([]);
    setText('');
    setPhraseId(0);
    setChangePhrase(1);
    setStop(false);
  }, [phrases]);


  useEffect(() => {
    let count = 0;
    let timer: NodeJS.Timeout;

    const getRandomTimeout = () => {
      const lambda = 1 / typingSpeed;
      const randomValue = Math.random();
      const timeout = -Math.log(1 - randomValue) / lambda;
      return Math.min(timeout, 200);
    };

    const animateText = () => {
      setText(phrases[phraseId].slice(0, count));
      count++;
      scrollToText();
      if (count === phrases[phraseId].length + 1) {
        onPhraseComplete();
        clearInterval(timer);
      } else {
        const timeout = getRandomTimeout();
        timer = setTimeout(animateText, timeout);
      }
    };

    if (!stop) {
      timer = setTimeout(animateText, 100); // execution starts here
    }
    return () => clearTimeout(timer);
  }, [changePhrase]);


  function isEmoji(str: string): boolean {
    const emojiRegex = /[\u{1F300}-\u{1F5FF}\u{1F900}-\u{1F9FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}][\u{FE0E}\u{FE0F}]?/ug;
    return emojiRegex.test(str);
  }

  const addMarginTop = (firstChar?: string, prevFirstChar?: string, prevLastChar?: string, prevLastWord?: string) => {
    // if the first character is a dash and the previous first character is not a dash: margin top
    if (firstChar && firstChar === '-' && prevFirstChar && prevFirstChar !== '-') return true;
    // this condition is for the case when the first character is a dash and the previous first character is a dash: no margin top
    if (firstChar && firstChar === '-') return false;
    // if the first character is not a dash and the previous first character is a dash: margin top
    if (prevFirstChar && prevFirstChar === '-' && firstChar !== '-') return true;
    // if the previous last character is a dot or an exclamation mark: margin top
    if (prevLastChar && (prevLastChar === '.' || prevLastChar === '!' || prevLastChar === ':')) return true;
    // if the previous last word is an emoji: margin top
    if (prevLastWord && isEmoji(prevLastWord)) return true;
    return false;
  };

  const scrollToText = () => {
    const div = document.getElementById('contentId');
    if (div) {
      div.scrollTop = div.scrollHeight;
    }
  };

  const NewLine = () => {
    if (writedText.length === 0 || text === '') {
      return (
        <p className={`${styles.textAnimation}`}>
          {text}
        </p>
      );
    }
    const lastText = writedText[writedText.length - 1];
    const prevLastWord = lastText.split(' ').pop();
    const prevFirstChar = lastText.charAt(0);
    const prevLastChar = lastText.charAt(lastText.length - 1);
    const firstChar = text.charAt(0);
    const className = addMarginTop(firstChar, prevFirstChar, prevLastChar, prevLastWord) ? styles.marginTop : '';
    return (
      <p className={`${styles.textAnimation} ${className}`}>
        {text}
      </p>
    );
  };

  const writedTexts = () => {
    let prevFirstChar = '';
    let prevLastChar = '';
    let prevLastWord = '';
    const texts = writedText.map((writed, index) => {
      const firstChar = writed.charAt(0);
      const lastChar = writed.charAt(writed.length - 1);
      const lastWord = writed.split(' ').pop();
      const className = addMarginTop(firstChar, prevFirstChar, prevLastChar, prevLastWord) ? styles.marginTop : '';
      prevFirstChar = firstChar;
      prevLastChar = lastChar;
      prevLastWord = lastWord || '';
      return <p className={className} key={index}>{writed}</p>;
    });
    return texts;
  };

  // do not show the full newLine when the text is already written
  if (writedText.includes(text)) {
    return (
      <div className={styles.textContainer}>
        {writedTexts()}
      </div>
    );
  }

  return (
    <div className={styles.textContainer}>
      {writedTexts()}
      {NewLine()}
    </div>
  );
};

export default TextAnimation;
