import { FC } from 'react';

import styles from './rrss.module.scss';
import discordLogo from '../../../assets/V1.01/discord.svg';
import twitterLogo from '../../../assets/V1.01/twitter.svg';
import tiktokLogo from '../../../assets/V1.01/tiktok.svg';
import instagramLogo from '../../../assets/V1.01/instagram.svg';
import linkedinLogo from '../../../assets/V1.01/linkedin.svg';

export const RRSS: FC = () => {

  const SocialObj = ({icon, url}: {icon: any, url: string}) => {
    return (
      <div>
        <a href={url} target="_blank" rel="noreferrer">
          <img src={icon} alt="" />
        </a>
      </div>
    );
  };
  
  return (
    <div className={styles.rrss}>
      <SocialObj icon={instagramLogo} url="https://instagram.com/quizify_com" />
      <SocialObj icon={discordLogo} url="https://discord.gg/MBv6HHtVTj" />
      <SocialObj icon={tiktokLogo} url="https://www.tiktok.com/@quizify.com" />
      <SocialObj icon={twitterLogo} url="https://twitter.com/quizify_com" />
      <SocialObj icon={linkedinLogo} url="https://www.linkedin.com/company/quizify" />
    </div>
  );
};
