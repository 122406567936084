import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../queryKeys';
import quizGPTService from '../../services/quizGPTService';


export const useQueryGetCategories = () => {
  return (
    useQuery({
      queryKey: [QueryKeys.GET_CATEGORIES],
      queryFn: async () => {
        try {
          const res = await quizGPTService.getAppContent();
          if (res.status === 200) {
            return res.data;
          }
        } catch (error) {
          return null;
        }
      },
      select: (data) => (
        data?.categoryTypes.flatMap(categoryType => categoryType.categories) ?? []
      )
    })
  );
};
