import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import { createPortal } from 'react-dom';
// components
import { CSSTransition } from 'react-transition-group';
import { Form, Formik } from 'formik';
// assets
import styles from './settingsForm.module.scss';
import banner from '../../../assets/settings/banner.png';
import { Button } from '../../button/Button';
import { BackDrop } from '../../backDrop/BackDrop';
import { useQuizGPT } from '../../../app/pages/quizGPT/quizGPT.hooks';
import { GeneralFields } from './fields/general';


export const SettingsForm: FC = () => {
  const {
    saveQuizGptSettings,
    setQuizGptSettings,
    quizGptSettings,
    showSettingModal,
    setShowSettingModal
  }
    = useQuizGPT();
  const { darkMode } = quizGptSettings;
  const transitionRef = useRef<HTMLFormElement>(null);
  const { t, i18n } = useTranslation();

  const handleLanguageSwitch = (value: string): void => {
    i18n.changeLanguage(value);
  };

  const handleDarkModeSwitch = (darkMode: boolean): void => {
    const html = document.querySelector('html') as HTMLElement;
    html.setAttribute('data-theme', darkMode ? 'dark' : 'light');
  };

  useEffect(() => {
    handleDarkModeSwitch(quizGptSettings.darkMode);
  }, [quizGptSettings.darkMode]);

  const FORM = (
    <Formik
      initialValues={{
        ...quizGptSettings,
        emoji: quizGptSettings.emoji ? 'true' : 'false',
        characters: quizGptSettings.characters ? 'true' : 'false',
        sound: quizGptSettings.sound ? 'true' : 'false',
        darkMode: quizGptSettings.darkMode ? 'true' : 'false'
      }}
      onSubmit={async (values: any, actions) => {
        try {
          handleLanguageSwitch(values.language);
          const cleanValues = {
            ...values,
            emoji: values.emoji === 'true' ? true : false,
            characters: values.characters === 'true' ? true : false,
            sound: values.sound === 'true' ? true : false,
            darkMode: values.darkMode === 'true'
          };
          setQuizGptSettings(cleanValues);
          setShowSettingModal(false);
          saveQuizGptSettings(cleanValues);
          actions.setSubmitting(false);
        }
        catch (error) {
          console.log(error);
        }
      }}>
      {() => (
        <Form
          className={styles.form}
          ref={transitionRef}
          onClick={(e) => e.stopPropagation()}
        >
          <figure className={styles.imgContainer}>
            {<img src={banner} alt="img" />}
          </figure>

          <div className={`${styles.fieldsList} ${darkMode && styles.fieldsListDark}`}>
            <div>
              <h3 className={styles.heading}>
                {t('settingsModal.title')}
              </h3>
              <p>
                {t('settingsModal.subtitle')}
              </p>
            </div>
            <div className={styles.fields}>
              <GeneralFields />
            </div>
            <div className={styles.buttons}>
              <Button
                color="purple"
                type="button"
                onClick={() => setShowSettingModal(false)}
              >
                {t('settingsModal.cancel')}
              </Button>
              <Button
                color="secondary"
                type="submit">
                {t('settingsModal.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <CSSTransition
      nodeRef={transitionRef}
      in={showSettingModal}
      unmountOnExit={true}
      timeout={{ enter: 200, exit: 180, appear: 200 }}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive
      }}>
      <>
        {createPortal(
          <BackDrop onClick={() => setShowSettingModal(false)}>
            <div className={styles.container}>
              {FORM}
            </div>
          </BackDrop>,

          document.querySelector('#portal') as HTMLElement
        )}
      </>
    </CSSTransition>
  );
};
