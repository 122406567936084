import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { TransitionWrapper } from './TransitionWrapper';
import { Sidebar } from '../sidebar/Sidebar';
import { useTranslation } from 'react-i18next';
import religionifyLogo from '../../assets/religionify/Logo_Religionify.svg';
import styles from './navbar.module.scss';
import { useQuizGPT } from '../../app/pages/quizGPT/quizGPT.hooks';


export const Navbar: FC = () => {
  const { t } = useTranslation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const { navbar } = useQuizGPT();

  return (
    <nav
      className={`${styles.navbar} ${!navbar.isOnTop ? styles.navbarBackground : ''}`}
    >
      <div
        className={styles.logoContainer}
      >
        <a href={t('navbar.logo') as string} target="_blank" rel="noreferrer">
          <img className={styles.logo} src={religionifyLogo} alt="logo" />
        </a>
        <h2>Religionify</h2>
      </div>

      <FontAwesomeIcon
        onClick={() => setToggleMenu(true)}
        className={styles.toggleButton}
        icon={faBars}
      />

      <div className={styles.menuContainer}>
        <TransitionWrapper
          toggle={toggleMenu}
          onClose={() => setToggleMenu(false)}
        >
          <Sidebar fromNavbar={true} />
        </TransitionWrapper>
      </div>
    </nav>
  );
};
