import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { QuizGPTProvider } from './pages/quizGPT/quizGPT.provider';
import { Sidebar } from '../components/sidebar/Sidebar';
import { Navbar } from '../components';
import { SettingsForm } from '../components/sidebar/settingForm/settingsForm';
import { useQuizGPT } from './pages/quizGPT/quizGPT.hooks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// assets
import styles from './app.module.scss';
import points from '../assets/V1.01/puntos.png';
import darkPoints from '../assets/V1.01/dots-darkmode.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppContainer: FC = () => {
  const { quizGptSettings: { darkMode }, activeDots } = useQuizGPT();
  return (
    <div className={styles.app}>
      {activeDots && <img
        src={darkMode ? darkPoints : points}
        className={styles.pointsRight}
        alt="points-1"
      />}

      <ToastContainer
        position="top-right"
      />
      <Navbar />
      <Sidebar />
      <SettingsForm />
      <Outlet />

      {activeDots && <img
        src={darkMode ? darkPoints : points}
        className={styles.pointsBottom}
        alt="points-2"
      />}
    </div>
  );
};


export const App: FC = () => {
  return (
    <QuizGPTProvider>
      <QueryClientProvider
        client={new QueryClient({
          defaultOptions: {
            queries: { refetchOnWindowFocus: false }
          }
        })}
      >
        <AppContainer />
      </QueryClientProvider>
    </QuizGPTProvider>
  );
};
