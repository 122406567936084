import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './support.module.scss';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modal/Modal';
import { Button } from '../../../button/Button';
import { FiveStars } from './components/FiveStars';
import { useMutationSentEmail } from '../../../../queries/emails/useMutationSentEmail';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ISentEmail } from '../../../../models/Email';
import { Field, Form, Formik, FormikHelpers } from 'formik';


interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const schemmaValidation = Yup.object<ISentEmail>({
  rating: Yup.number(),
  message: Yup.string().required(),
});

export const FeedbackModal: FC<Props> = ({ onToggle, isOpen }) => {
  const { t } = useTranslation();
  const sentEmail = useMutationSentEmail();

  const handleEmailSubmit = async (values: ISentEmail, actions: FormikHelpers<ISentEmail>) => {
    try {
      onToggle();
      actions.setSubmitting(false);
      actions.resetForm();
      await toast.promise(
        sentEmail.mutateAsync({
          message: values.message,
          rating: `${values.rating} ${values.rating === '1' ? 'star' : 'stars'}`
        }),
        {
          pending: 'Enviando mensaje...',
          success: 'Mensaje enviado con éxito',
          error: 'Ocurrió un error al enviar el mensaje'
        }
      );
    } catch (error) {
      return null;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <div className={styles.form} onClick={(e) => e.stopPropagation()}>
        <button
          type="button"
          onClick={onToggle}
          className={styles.closeButton}
          aria-label="close"
        >
          <FontAwesomeIcon
            className={styles.closeIcon}
            size="lg"
            fontWeight={900}
            icon={faXmark}
          />
        </button>
        <Formik
          validationSchema={schemmaValidation}
          initialValues={{ rating: '', message: '' }}
          onSubmit={handleEmailSubmit}
        >
          {() => (
            <Form className={styles.content}>

              <div className={styles.header}>
                <h3 className={styles.heading}>
                  {t('feedbackModal.title')}
                </h3>

                <FiveStars />

                <p>
                  {t('feedbackModal.subtitle')}
                </p>
              </div>

              <Field
                as="textarea"
                className={styles.textarea}
                rows={4}
                placeholder={`${t('feedbackModal.placeholder')}`}
                name="message"
                id="message"
              />

              <Button type="submit" className={styles.button} color="secondary">
                {t('feedbackModal.button')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
