import { FC, useEffect } from 'react';
import styles from './quizGPT.module.scss';
import { useQuizGPT } from './quizGPT.hooks';
import LottiePLayer from 'lottie-react';
import jesusLottie from '../../../assets/religionify/JSONs/1.1.json';

// components
import { Main, LoadingResults } from './components';
// swiper
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase';
import localStorageService from '../../../services/localStorageService';


export const QuizGPT: FC = () => {
  const { setOuterSwiper, setUser, getHistory, setAccessToken } = useQuizGPT();

  const init = () => {
    getHistory();
  };

  // Set up Firebase authentication state observer
  useEffect(() => {
    init();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const token = await user.getIdToken();
        localStorageService.save('accessToken', token);
        setAccessToken(token);
      } else {
        setUser(null);
        localStorageService.delete('accessToken');
        setAccessToken('');
      }
    });

    return unsubscribe;
  }, []);

  return (
    <div id="container" className={styles.container}>
      <Swiper
        className={styles.swiper}
        spaceBetween={30}
        autoHeight={true}
        allowTouchMove={false}
        touchRatio={0}
        grabCursor={false}
        onSwiper={(swp) => setOuterSwiper(swp)}
        speed={300}
      >
        <SwiperSlide className={styles.swiperSliderMain}>
          <Main />
        </SwiperSlide>

        <SwiperSlide className={styles.swiperSliderResult}>
          <LoadingResults />
        </SwiperSlide>
      </Swiper>

      <LottiePLayer
        className={styles.jesus}
        animationData={jesusLottie}
        muted={true}
      />
    </div>
  );
};
