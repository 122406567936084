import { FC } from 'react';
import LottiePLayer from 'lottie-react';

// assets
import styles from './loading.module.scss';
import animStep2 from '../../assets/JSONs Loading Bar/paso-n2.json';

export const Loading: FC = () => {

  return (
    <div>
      <figure className={styles.figure}>
        <LottiePLayer
          className={styles.lottie}
          animationData={animStep2}
          loop={true}
        />
        <article>
          <h3>{'Loading...'}</h3>

          <p>{}</p>
        </article>
      </figure>
    </div>
  );
};
