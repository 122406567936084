import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import styles from './lesson.module.scss';
import LottiePLayer from 'lottie-react';
import { Button } from '../../../components';
import { useTranslation } from 'react-i18next';
import TextAnimation from './components/textAnimation';
import { useQuizGPT } from '../quizGPT/quizGPT.hooks';
import { Lesson as Lessons } from '../../../models/QuizGTPResponse';
import { Loading } from '../../../components/loading/loading';
import { useNavigate, useParams } from 'react-router-dom';
import arrow from '../../../assets/arrow.png';

// lotties
import lottie1_1 from '../../../assets/religionify/JSONs/1.1.json';
import lottie1_2 from '../../../assets/religionify/JSONs/1.2.json';
import lottie1_3 from '../../../assets/religionify/JSONs/1.3.json';
import lottie1_4 from '../../../assets/religionify/JSONs/1.4.json';
import lottie1_5 from '../../../assets/religionify/JSONs/1.5.json';

import lottie2_1 from '../../../assets/religionify/JSONs/2.1.json';
import lottie2_2 from '../../../assets/religionify/JSONs/2.2.json';
import lottie2_3 from '../../../assets/religionify/JSONs/2.3.json';
import lottie2_4 from '../../../assets/religionify/JSONs/2.4.json';
import lottie2_5 from '../../../assets/religionify/JSONs/2.5.json';

import lottie3_1 from '../../../assets/religionify/JSONs/3.1.json';
import lottie3_2 from '../../../assets/religionify/JSONs/3.2.json';
import lottie3_3 from '../../../assets/religionify/JSONs/3.3.json';
import lottie3_4 from '../../../assets/religionify/JSONs/3.4.json';
import lottie3_5 from '../../../assets/religionify/JSONs/3.5.json';

import lottie5_1 from '../../../assets/religionify/JSONs/5.1.json';
import lottie5_2 from '../../../assets/religionify/JSONs/5.2.json';
import lottie5_3 from '../../../assets/religionify/JSONs/5.3.json';
import lottie5_4 from '../../../assets/religionify/JSONs/5.4.json';
import lottie5_5 from '../../../assets/religionify/JSONs/5.5.json';

import lottie6_1 from '../../../assets/religionify/JSONs/6.1.json';
import lottie6_2 from '../../../assets/religionify/JSONs/6.2.json';
import lottie6_3 from '../../../assets/religionify/JSONs/6.3.json';
import lottie6_4 from '../../../assets/religionify/JSONs/6.4.json';
import lottie6_5 from '../../../assets/religionify/JSONs/6.5.json';


const lottiesMap = new Map([
  [0, [lottie1_1, lottie1_2, lottie1_3, lottie1_4, lottie1_5]],
  [1, [lottie2_1, lottie2_2, lottie2_3, lottie2_4, lottie2_5]],
  [2, [lottie3_1, lottie3_2, lottie3_3, lottie3_4, lottie3_5]],
  [3, [lottie5_1, lottie5_2, lottie5_3, lottie5_4, lottie5_5]],
  [4, [lottie6_1, lottie6_2, lottie6_3, lottie6_4, lottie6_5]]
]);

const numLotties = lottiesMap.get(0)?.length ?? 5;

const genRandomIndex = (x = numLotties) => Math.floor(Math.random() * x);

interface LessonProps {
  goBack?: boolean,
  playQuiz?: boolean
}

export const Lesson: FC<LessonProps> = ({ goBack = true, playQuiz = true }) => {
  const { quizId } = useParams<{ quizId: string }>();
  const { t } = useTranslation();
  const { data, isLoading, getLessonByQuizId, resetForm } = useQuizGPT();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(isLoading);

  const [currentLottie, setCurrentLottie] = useState(genRandomIndex());
  const randomLottieList = useRef<number>(genRandomIndex());

  let lessons = data?.lesson as Lessons[];

  const [currentLesson, setCurrentLesson] = useState(0);
  const [currentContent, setCurrentContent] = useState(lessons ? lessons[0].content : []);

  const title = lessons ? lessons[currentLesson].title : '';

  const setData = (): void => {
    lessons = data?.lesson as Lessons[];
    setCurrentContent(lessons ? lessons[0].content : []);
    setCurrentLesson(0);
  };

  useEffect(() => {
    setData();
  }, [data]);

  const init = async () => {
    // only get the lesson if it is not the same as the previous one
    if (!data.quizId || !data.lesson || (data.quizId !== Number(quizId))) {
      setLoading(true);
      await Promise.all([
        new Promise(resolve => setTimeout(resolve, 2000)),
        getLessonByQuizId(Number(quizId))
      ]); // Add a delay of 2000 milliseconds (2 seconds)
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [quizId]);

  const handleNext = (): void => {
    if (currentLesson < lessons.length - 1) {
      setCurrentLesson(prev => prev + 1);
      setCurrentContent(lessons[currentLesson + 1].content);
      setCurrentLottie(prev => ++prev % (numLotties - 1));
    }
    else {
      resetForm();
      setTimeout(() => navigate('/'), 200);
    }
  };

  const handlePrev = (): void => {
    if (currentLesson > 0) {
      setCurrentLesson(prev => prev - 1);
      setCurrentContent(lessons[currentLesson - 1].content);
      setCurrentLottie(prev => (
        prev === 0
          ? (numLotties - 1)
          : --prev % (numLotties - 1)
      ));
    }
  };

  if (loading || currentContent.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      </div>
    );
  }

  const handleBackButtonClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    resetForm();
    navigate('/');
  };

  return (
    <div className={styles.container}>

      <div className={styles.cloud}>
        {goBack && <div className={styles.back} onClick={handleBackButtonClick}>
          <img src={arrow} />
        </div>
        }
        <div className={styles.content} key={currentLesson} id="contentId">
          <div className={styles.title}>
            {title}
          </div>
          <TextAnimation
            phrases={currentContent}
            typingSpeed={35}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          type="submit"
          onClick={handlePrev}
        >
          {t('lessons.back')}
        </Button>
        {
          !(!playQuiz && currentLesson === lessons?.length - 1) && <Button
            type="submit"
            color="secondary"
            onClick={handleNext}
          >
            {currentLesson === lessons?.length - 1 ? t('lessons.finish') : t('lessons.continue')}
          </Button>
        }
      </div>
      <LottiePLayer
        className={styles.animation}
        animationData={lottiesMap.get(randomLottieList?.current)?.[currentLottie]}
        muted={true}
      />
    </div>
  );
};
