import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { QuizGPTGenerators } from '../quizGPTGenerators/QuizGPTGenerators';
import { CardsListChoices } from '../cardsListChoices/CardsListChoices';
import religionifyLogo from '../../../../../assets/religionify/Logo_Religionify.svg';
// assets
import styles from './main.module.scss';
import { TrendingSnapBooks } from './trendingSnapBooks/TrendingSnapBooks';
import { useQuizGPT } from '../../quizGPT.hooks';


export const Main: FC = () => {
  const { t } = useTranslation();
  const { navbar } = useQuizGPT();

  return (
    <div id="main" className={styles.wrapper}>
      <div
        className={styles.main}
        onScroll={(e) => {
          const targetEl = e.target as HTMLDivElement;
          if (targetEl.scrollTop < 110) {
            navbar.setIsOnTop(true);
          } else {
            navbar.setIsOnTop(false);
          }
        }}
      >
        <div className={styles.quizGPTOptions}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={religionifyLogo} alt="logo" />
            <h2>Religionify</h2>
          </div>

          <div className={styles.textContent}>
            <h1 className={styles.heading}>
              {t('slideContainer.title')}
            </h1>
            <p>
              {t('slideContainer.paragraph')}
            </p>
          </div>
        </div>
        <CardsListChoices />

        <QuizGPTGenerators />

        <TrendingSnapBooks />
      </div>
    </div>
  );
};
