type ItemKeys = (
  'lastCourseId' |
  'toggleCourseId' |
  'theme' |
  'muteSound' |
  'purchasedCoverId' |
  'purchasedOutfitId' |
  'accessToken' |
  'userID' |
  'userIconIndex' |
  'GPT_QUIZIFY_SETTINGS' |
  'GPT_QUIZIFY_HISTORY' |
  'activateFormModal'
);
// all the keys here


class StorageService {

  get<T>(itemKey: ItemKeys): T | null | string {
    const localItem = localStorage.getItem(itemKey);

    if (itemKey === 'theme' || itemKey === 'muteSound' || itemKey === 'activateFormModal' || itemKey === 'GPT_QUIZIFY_SETTINGS' || itemKey === 'GPT_QUIZIFY_HISTORY') {
      return localItem ? JSON.parse(localItem) : null;
    }
    return localItem;
  }

  save<T>(itemKey: ItemKeys, item: T): void {
    const data = typeof item === 'string' ? item : JSON.stringify(item);
    localStorage.setItem(itemKey, data);
  }

  delete(itemKey: ItemKeys): void {
    localStorage.removeItem(itemKey);
  }
}


export default new StorageService();
