import { FC } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import styles from '../settingsForm.module.scss';
import sound from '../../../../assets/settings/sound.png';
import world from '../../../../assets/settings/world.png';
import sunIcon from '../../../../assets/settings/sun.png';



export const GeneralFields: FC = () => {

  const { t } = useTranslation();
  return (
    <div className={styles.fieldGroup}>
      <p className={styles.title}>General</p>
      <div className={styles.field}>
        <div className={styles.label}>
          <img src={sound} alt="sound icon" />
          <p>{t('settingsModal.general.sounds.label')}</p>
        </div>
        <Field name="sound" as="select">
          <option value="true">{t('settingsModal.general.sounds.on')}</option>
          <option value="false">{t('settingsModal.general.sounds.off')}</option>
        </Field>
      </div>

      <div className={styles.field}>
        <div className={styles.label}>
          <img src={world} alt="world icon" />
          <p>{t('settingsModal.general.language.label')}</p>
        </div>
        <Field name="language" as="select">
          <option value="en">{t('settingsModal.general.language.english')}</option>
          <option value="es">{t('settingsModal.general.language.spanish')}</option>
          <option value="ko">{t('settingsModal.general.language.korean')}</option>
        </Field>
      </div>

      <div className={styles.field}>
        <div className={styles.label}>
          <img src={sunIcon} alt="theme icon" />
          <p>{t('settingsModal.general.theme.label')}</p>
        </div>
        <Field name="darkMode" as="select">
          <option value="false">{t('settingsModal.general.theme.light')}</option>
          <option value="true">{t('settingsModal.general.theme.dark')}</option>
        </Field>
      </div>
    </div>
  );
};
