import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styles from './modal.module.scss';
import { BackDrop } from '../backDrop/BackDrop';


interface Props {
  isOpen: boolean,
  onClose: () => void,
  children: React.ReactNode
}

export const Modal: FC<Props> = ({ children, isOpen, onClose }) => {
  const transitionRef = useRef<HTMLDivElement>(null);
  return (
    <CSSTransition
      nodeRef={transitionRef}
      in={isOpen}
      unmountOnExit={true}
      timeout={{ enter: 200, exit: 180, appear: 200 }}
      classNames={{
        enter: styles.modalEnter,
        enterActive: styles.modalEnterActive,
        exit: styles.modalExit,
        exitActive: styles.modalExitActive
      }}>
      <>
        {createPortal(
          <BackDrop onClick={onClose}>
            <div className={styles.container}>
              {children}
            </div>
          </BackDrop>,
          document.querySelector('#portal') as HTMLElement
        )}
      </>
    </CSSTransition>
  );
};
