// libs
import { FC, ReactNode, useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { QuizGPTContext } from './quizGPT.context';
import { useTranslation } from 'react-i18next';

// types/interfaces
import { InputTypes } from './components/interfaces';
import { ReligionyfyTypes } from '../../../models/QuizGptGenerators';
import { PayloadReq, QuizGPTCourseReq, QuizGPTLessonPlusQuizReq, QuizGPTQuizOnlyReq } from '../../../models/QuizGPTRequest';
import { CourseI, DataResponse, HistoryObjI, QuizGPTLessonPlusQuizRes, QuizGPTQuizOnlyRes } from '../../../models/QuizGTPResponse';
import { QuizGptSettings } from '../../../models/QuizGptSettings';

// services
import quizGPTService from '../../../services/quizGPTService';
import localStorageService from '../../../services/localStorageService';
import { useDebouncedState } from '../../../hooks/useDebouncedState';


const settings = localStorageService.get<QuizGptSettings>('GPT_QUIZIFY_SETTINGS') as QuizGptSettings;

const SETTINGS_DEFAULT_VALUES: Partial<QuizGptSettings> = {
  characters: true,
  educator: 'Professor',
  emoji: true,
  lessonsDifficulty: 'High school',
  media: 'icon',
  quizzesDifficulty: 'High school',
  style: 'Friendly',
  sound: true,
  model: 'gpt-3.5-turbo',
  darkMode: typeof settings?.darkMode === 'boolean' ? settings?.darkMode : false
};


interface Props {
  children: ReactNode
}

export const QuizGPTProvider: FC<Props> = ({ children }) => {
  const [data, setData] = useState({} as DataResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [activeDots, setActiveDots] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState('');
  const [selectedQuizGen, setSelectedQuizGen] = useState(ReligionyfyTypes.CHAT);
  const [payload, setPayload] = useState({} as PayloadReq);
  const [outerSwiper, setOuterSwiper] = useState({} as SwiperCore);
  const [quizTopic, setQuizTopic] = useState('');
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [history, setHistory] = useState([] as HistoryObjI[]);
  const [user, setUser] = useState(null);
  const [quizIdLoading, setQuizIdLoading] = useState(0);
  const { i18n } = useTranslation();
  const [showSnapBookSlide, setShowSnapBookSlide] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [isOnTop, setIsOnTop] = useDebouncedState(true);

  const [quizGptSettings, setQuizGptSettings] = useState({
    ...SETTINGS_DEFAULT_VALUES,
    language: i18n.resolvedLanguage === 'es' ? 'es' : 'en'
  } as QuizGptSettings);

  const resetForm = (): void => {
    setData({} as DataResponse);
    setIsLoading(false);
    setHasLoaded(false);
    setActiveDots(true);
    setError('');
  };

  const saveQuizGptOnlyOnLocalStorage = (data: QuizGPTQuizOnlyRes, payload: QuizGPTQuizOnlyReq): void => {
    if (data.url) {
      const tempHistory = history;
      const obj = {
        url: data.url,
        id: data.id,
        topic: payload.topic,
        language: payload.language,
        level: payload.difficulty,
        model: payload.model,
        createdAt: new Date().toISOString(),
        type: ReligionyfyTypes.QUIZ,
        quizId: data.quizId
      };
      tempHistory.push(obj as HistoryObjI);
      localStorageService.save('GPT_QUIZIFY_HISTORY', history);
      setHistory(tempHistory);
    }
  };

  const saveLessonOnLocalStorage = (data: QuizGPTLessonPlusQuizRes, payload: QuizGPTLessonPlusQuizReq): void => {
    if (data.url && data.lesson?.length > 0) {
      const tempHistory = history;
      const obj = {
        url: data.url,
        id: data.id,
        topic: payload.topic,
        language: payload.language,
        level: payload.difficulty,
        model: quizGptSettings.model,
        createdAt: new Date().toISOString(),
        type: ReligionyfyTypes.LESSON,
        quizId: data.quizId
      };
      tempHistory.push(obj);
      localStorageService.save('GPT_QUIZIFY_HISTORY', tempHistory);
      setHistory(tempHistory);
    }
  };

  const saveChatLocalStorage = (data: CourseI, payload: QuizGPTCourseReq): void => {
    if (data.id && data.sections?.length > 0) {
      const tempHistory = history;
      const obj = {
        id: data.id,
        topic: payload.topic,
        language: payload.language,
        level: payload.level,
        model: quizGptSettings.model,
        createdAt: new Date().toISOString(),
        type: ReligionyfyTypes.CHAT,
      };
      tempHistory.push(obj);
      localStorageService.save('GPT_QUIZIFY_HISTORY', tempHistory);
      setHistory(tempHistory);
    }
  };

  const getHistory = (): void => {
    const obj = localStorageService.get('GPT_QUIZIFY_HISTORY');
    setHistory(obj ? obj as HistoryObjI[] : []);
  };

  const deleteHistoryObj = (id: number): void => {
    const newHistory = history.filter((item) => item.id !== id);
    localStorageService.save('GPT_QUIZIFY_HISTORY', newHistory);
    setHistory(newHistory);
  };

  const generateChat = async (payload: QuizGPTCourseReq) => {
    setPayload(payload);
    setIsLoading(true);
    quizGPTService
      .generateCourse(payload)
      .then(({ data }) => {
        setData(data);
        saveChatLocalStorage(data, payload);
        setActiveDots(false);
        setHasLoaded(true);
      })
      .catch((err: any) => setError(err.response?.data?.message || err.message))
      .finally(() => setIsLoading(false));
  };


  const generateLesson = async (payload: QuizGPTLessonPlusQuizReq) => {
    // lesson generated from the form
    if (!payload.quizId) {
      setPayload(payload);
      setIsLoading(true);
    }
    setQuizIdLoading(payload.quizId ?? 0);
    if (data?.questions?.length) {
      setData({} as QuizGPTLessonPlusQuizRes);
    }
    quizGPTService
      .generateLesson(payload)
      .then(({ data }) => {
        // only save lessons created from the form
        if (!payload?.quizId) {
          setData(data);
          saveLessonOnLocalStorage(data, payload);
          setHasLoaded(true);
        }
        setQuizIdLoading(0);
      })
      .catch((err: any) => setError(err.response?.data?.message || err.message))
      .finally(() => setIsLoading(false));
  };

  const getLessonByQuizId = async (quizId: number) => {
    return quizGPTService
      .getLessonById(quizId)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err: any) => setError(err.response?.data?.message || err.message))
      .finally(() => setIsLoading(false));
  };

  const getCourseById = async (quizId: number) => {
    return quizGPTService
      .getCourseById(quizId)
      .then(({ data }) => {
        setData(data);
        setActiveDots(false);
      })
      .catch((err: any) => setError(err.response?.data?.message || err.message))
      .finally(() => setIsLoading(false));
  };

  const generateOnlyQuiz = async (payload: QuizGPTQuizOnlyReq, type: InputTypes) => {
    setPayload(payload);
    setIsLoading(true);
    if (data?.questions?.length) {
      setData({} as QuizGPTQuizOnlyRes);
    }
    if (type === 'topic') {
      quizGPTService
        .generateQuickQuiz(payload)
        .then(({ data }) => {
          setData(data);
          saveQuizGptOnlyOnLocalStorage(data, payload);
          setHasLoaded(true);
        })
        .catch((err: any) => setError(err.response?.data?.message || err.message))
        .finally(() => setIsLoading(false));
    }
    if (type === 'text') {
      quizGPTService
        .generateTextBasedQuiz(payload)
        .then(({ data }) => {
          setData(data);
          saveQuizGptOnlyOnLocalStorage(data, payload);
          setHasLoaded(true);
        })
        .catch((err: any) => setError(err.response?.data?.message || err.message))
        .finally(() => setIsLoading(false));
    }
  };

  const shouldShowLoginModal = (): boolean => {
    if (user) {
      return false;
    }
    const response = history.length > 1 && !user;
    setShowLoginModal(true);
    return response;
  };

  const saveQuizGptSettings = (values: QuizGptSettings): void => {
    localStorageService.save('GPT_QUIZIFY_SETTINGS', values);
  };

  useEffect(() => {
    const settings: any = localStorageService.get('GPT_QUIZIFY_SETTINGS');
    if (settings) {
      // clean 0 & 1 values
      ['emoji', 'characters', 'sound'].map((item) => {
        if (settings[item] && (typeof settings[item] === 'string')) {
          settings[item] = settings[item] === '1';
        }
      });

      setQuizGptSettings({
        ...settings,
        language: i18n.resolvedLanguage === 'es' ? 'es' : 'en',
      });
      saveQuizGptSettings(settings);
    }
  }, []);

  return (
    <QuizGPTContext.Provider
      value={{
        data,
        payload,
        quizTopic,
        setQuizTopic,
        generateOnlyQuiz,
        generateLesson,
        generateChat,
        isLoading,
        hasLoaded,
        error,
        outerSwiper,
        showSettingModal,
        setShowSettingModal,
        setOuterSwiper,
        getHistory,
        showLoginModal,
        setShowLoginModal,
        user,
        setUser,
        shouldShowLoginModal,
        quizGptSettings,
        setQuizGptSettings,
        saveQuizGptSettings,
        selectedQuizGen,
        setSelectedQuizGen,
        getLessonByQuizId,
        getCourseById,
        deleteHistoryObj,
        history,
        setHistory,
        resetForm,
        activeDots,
        quizIdLoading,
        showSnapBookSlide,
        setShowSnapBookSlide,
        accessToken,
        setAccessToken,
        navbar: {
          isOnTop,
          setIsOnTop,
        },
        showSupportModal,
        setShowSupportModal,
      }}>
      {children}
    </QuizGPTContext.Provider>
  );
};
