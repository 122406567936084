import { FC, MouseEvent, useEffect } from 'react';
import styles from './history.module.scss';
import { useQuizGPT } from '../../../app/pages/quizGPT/quizGPT.hooks';
import { HistoryObjI } from '../../../models/QuizGTPResponse';
import quiz from '../../../assets/sidebar/quiz.png';
import courseIcon from '../../../assets/sidebar/course.png';
import storyTellingIcon from '../../../assets/sidebar/storytelling.png';
import trashIcon from '../../../assets/sidebar/bin.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReligionyfyTypes } from '../../../models/QuizGptGenerators';


const ObjContainer: FC<{ item: HistoryObjI }> = ({ item }) => {

  const navigate = useNavigate();
  const { deleteHistoryObj } = useQuizGPT();

  const handleClick = (): void => {
    if (item.type === ReligionyfyTypes.LESSON) {
      navigate(`lessons/${item.quizId}`);
    }
    else if (item.type === ReligionyfyTypes.CHAT) {
      navigate(`chat/${item.id}`);
    }
    else if (item.type === ReligionyfyTypes.QUIZ) {
      navigate(`play-quiz/${item.id}`);
    }
  };

  const deleteObj = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    deleteHistoryObj(item.id);
  };

  const getIcon = (type: string): string => {
    switch (type) {
      case ReligionyfyTypes.LESSON:
        return storyTellingIcon;
      case ReligionyfyTypes.CHAT:
        return courseIcon;
      default:
        return quiz;
    }
  };

  return (
    <div className={styles.historyObj} onClick={handleClick}>
      <img src={getIcon(item.type)} />
      <p title={item.topic}>{item.topic}</p>
      <div className={styles.delete} onClick={deleteObj}>
        <img src={trashIcon} />
      </div>
    </div>
  );
};


export const History: FC = () => {
  const { t } = useTranslation();
  const { history, getHistory } = useQuizGPT();

  useEffect(() => {
    getHistory();
  }, []);

  const getObjects = () => {
    // Get the current date and time
    const now: Date = new Date();

    // Filter objects created in the last 30 days
    const objectsLast30Days: HistoryObjI[] = history
      .filter((obj: HistoryObjI) => {
        const objCreatedAt: Date = new Date(Date.parse(obj.createdAt));
        const diffTime: number = Math.abs(now.getTime() - objCreatedAt.getTime());
        const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays <= 30;
      })
      .sort((a: HistoryObjI, b: HistoryObjI) => {
        const aCreatedAt: Date = new Date(Date.parse(a.createdAt));
        const bCreatedAt: Date = new Date(Date.parse(b.createdAt));
        return bCreatedAt.getTime() - aCreatedAt.getTime(); // compare dates in descending order
      });

    // Group objects by whether they were created today, yesterday, in the previous 7 days, or in the previous 30 days
    const groups = objectsLast30Days.reduce((acc: any, obj: HistoryObjI) => {
      const objCreatedAt: Date = new Date(obj.createdAt);
      //const diffTime: number = Math.abs(now.getTime() - objCreatedAt.getTime());
      const diffTime: number = Math.abs(now.getTime() - objCreatedAt.getTime() + objCreatedAt.getTimezoneOffset() * 60 * 1000);
      const diffDays: number = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 0) {
        acc.today.push(obj);
      } else if (diffDays === 1) {
        acc.yesterday.push(obj);
      } else if (diffDays <= 7) {
        acc.last7Days.push(obj);
      } else if (diffDays <= 30) {
        acc.last30Days.push(obj);
      }
      return acc;
    }, { today: [], yesterday: [], last7Days: [], last30Days: [] });
    return [groups, objectsLast30Days];
  };

  const [groups, objectsLast30Days] = getObjects();

  return (
    <div className={styles.history}>

      {objectsLast30Days.length === 0 && (
        <div className={styles.dayContainer}>
          <p className={styles.day}>{t('sidebar.history.today')}</p>
          <p className={styles.emptyText}>
            {t('sidebar.history.empty')}
          </p>
        </div>
      )}

      {groups.today?.length > 0 && (
        <div className={styles.dayContainer}>
          <p className={styles.day}>{t('sidebar.history.today')}</p>
          {groups.today.map((item: HistoryObjI, index: number) => {
            return (
              <div key={index}>
                <ObjContainer item={item} />
              </div>
            );
          }
          )}
        </div>
      )}

      {groups.yesterday?.length > 0 && (
        <div className={styles.dayContainer}>
          <p className={styles.day}>{t('sidebar.history.yesterday')}</p>
          {groups.yesterday.map((item: HistoryObjI, index: number) => {
            return (
              <div key={index}>
                <ObjContainer item={item} />
              </div>
            );
          }
          )}
        </div>
      )}

      {groups.last7Days?.length > 0 && (
        <div className={styles.dayContainer}>
          <p className={styles.day}>{t('sidebar.history.7daysAgo')}</p>
          {groups.last7Days.map((item: HistoryObjI, index: number) => {
            return (
              <div key={index}>
                <ObjContainer item={item} />
              </div>
            );
          }
          )}
        </div>
      )}

      {groups.last30Days?.length > 0 && (
        <div className={styles.dayContainer}>
          <p className={styles.day}>{t('sidebar.history.30daysAgo')}</p>
          {groups.last30Days.map((item: HistoryObjI, index: number) => {
            return (
              <div key={index}>
                <ObjContainer item={item} />
              </div>
            );
          }
          )}
        </div>
      )}
    </div>
  );
};
