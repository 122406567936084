import { FC } from 'react';

import styles from './sidebar.module.scss';
import { useTranslation } from 'react-i18next';
import { History } from './history/History';
import { Options } from './options/Options';
import { RRSS } from './rrss/RRSS';
import { LoginForm } from './loginForm/loginForm';

interface Props {
  fromNavbar?: boolean
}

export const Sidebar: FC<Props> = ({ fromNavbar }) => {
  const { t } = useTranslation();
  return (
    <aside onClick={(e) => e.stopPropagation()} className={fromNavbar ? styles.navbar : styles.sidebar}>
      <h1>{t('sidebar.title')}</h1>
      <History />
      <div className={styles.bottom}>
        <hr />
        <Options />
        <hr />
        <RRSS />

        <LoginForm />
      </div>
    </aside>
  );
};
