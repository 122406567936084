import ReactDOM from 'react-dom/client';
import { router } from './app/app.router';
import { RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './firebase';
import './globals.scss';
import './i18next';


ReactDOM
  .createRoot(document.querySelector('#root') as HTMLElement)
  .render(
    <RouterProvider router={router} />
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
