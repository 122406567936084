// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyBU4f15g5oyz7BAitYoop7bF-ZcCy72CL4',
//   authDomain: 'quizify-42843.firebaseapp.com',
//   projectId: 'quizify-42843',
//   storageBucket: 'quizify-42843.appspot.com',
//   messagingSenderId: '362558555616',
//   appId: '1:362558555616:web:e70ef077753b3f402a9785',
//   measurementId: 'G-6ZDR1DW4V4'
// };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: 'AIzaSyBHBUrkPyy0C_309VXjsFmCSIIChfb6Urw',
  // authDomain: 'larnu-299823.firebaseapp.com',
  // projectId: 'larnu-299823',
  // storageBucket: 'larnu-299823.appspot.com',
  // messagingSenderId: '547819483444',
  // appId: '1:547819483444:web:f7dc7f00b221b1f6fcb561',
  // measurementId: 'G-MYBE8PRJF0'

  apiKey: 'AIzaSyDCDTON3UhyeJmLwtveC8xz59EgrJejX4c',
  authDomain: 'vertical-cirrus-414312.firebaseapp.com',
  projectId: 'vertical-cirrus-414312',
  storageBucket: 'vertical-cirrus-414312.appspot.com',
  messagingSenderId: '206398930173',
  appId: '1:206398930173:web:49c8b871acb3ebede35294',
  measurementId: 'G-E034ZR9X99'
};


// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
