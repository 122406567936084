import { FC } from 'react';
import Player from 'lottie-react';
// assets
import religionifyLogo from '../../../assets/religionify/Logo_Religionify.svg';
import { Button } from '../../../components/button/Button';
import animationJson from '../../../assets/Animations/religionify/notFound.json';
import styles from './error.module.scss';
import { useTranslation } from 'react-i18next';
import { useQuizGPT } from '../quizGPT/quizGPT.hooks';


export const Error: FC = () => {
  const { error, } = useQuizGPT();
  const { t } = useTranslation();

  return (
    <div className={styles.page}>
      <div className={styles.card}>

        <div className={styles.logoContainer}>
          <img className={styles.logo} src={religionifyLogo} alt="logo" />
          <h2>Religionify</h2>
        </div>

        <Player
          animationData={animationJson}
          className={styles.einstein}
          autoplay
          loop
        />

        <div className={styles.cardBody}>
          <h3>{t('errorPage.title')}</h3>

          <div>
            <p>{t('errorPage.message')}</p>
            <p>{t('errorPage.question')}</p>
          </div>

          <div>
            <p style={{ color: 'red' }}>{error}</p>
          </div>

          <Button
            color="success"
            onClick={() => window.location.href = '/'}
            style={{ marginTop: '1.2rem' }}>
            {t('errorPage.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
