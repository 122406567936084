import { styled } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: '1.4rem',
  borderRadius: 7,
  width: '100%',

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'var(--base-200)',
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: 'var(--success-50)',
  },
}));
