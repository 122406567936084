import { FC, HTMLAttributes, ReactNode } from 'react';
import styles from './backDrop.module.scss';



interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
}
export const BackDrop: FC<Props> = ({ children, ...props }) => {
  return (
    <div {...props} className={styles.container}>
      {children}
    </div>
  );
};
