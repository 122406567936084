import { FC, useEffect, useRef, useState } from 'react';
import styles from './trendingSnapBooks.module.scss';
import { SwiperSlide, Swiper, SwiperRef } from 'swiper/react';
import { useQuizGPT } from '../../../quizGPT.hooks';
import { useQueryGetCategories } from '../../../../../../queries/categories/useQueryGetCategories';
import { Category } from '../../../../../../models/religionify/Categories';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';


export const TrendingSnapBooks: FC = () => {
  const { t } = useTranslation();
  const { setShowSnapBookSlide, outerSwiper } = useQuizGPT();
  const { data: categories = [], isSuccess, isFetching } = useQueryGetCategories();
  const [selectedCategory, setSelectedCategory] = useState(0);

  const handleSlideNext = () => {
    setShowSnapBookSlide(true);
    setTimeout(() => outerSwiper.slideNext(), 160);
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedCategory(categories[0]?.id);
    }
  }, [isSuccess]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.container}>

        <h2 className={styles.heading}>{t('exploreTrendingContent')}</h2>

        <FilterButtons
          isLoading={isFetching}
          categories={categories}
          onSelecteCategory={(category) => setSelectedCategory(category.id)}
        />

        <Cards
          categories={categories}
          isLoading={isFetching}
          onSlideNext={handleSlideNext}
          selectedCategory={selectedCategory}
        />
      </div>
    </div>
  );
};


interface FilterButtonProps {
  onSelecteCategory: (selectedCategory: Category) => void
  categories: Category[],
  isLoading: boolean
}

const FilterButtons: FC<FilterButtonProps> = ({ categories, onSelecteCategory, isLoading }) => {
  const [selected, setSelected] = useState('');
  const swiperRef = useRef<SwiperRef>(null);

  useEffect(() => {
    setSelected(categories[0]?.name);
  }, [categories]);

  if (isLoading) {
    return (
      <ul className={styles.skeletons} style={{ width: '120%' }}>
        <Skeleton
          variant="rounded"
          sx={{ backgroundColor: 'var(--bg-skeleton-50)', height: 60, width: 250, padding: 0 }}
        />
        <Skeleton
          variant="rounded"
          sx={{ backgroundColor: 'var(--bg-skeleton-50)', height: 60, width: 250 }}
        />
        <Skeleton
          variant="rounded"
          sx={{ backgroundColor: 'var(--bg-skeleton-50)', height: 60, width: 250 }}
        />
      </ul>
    );
  }
  return (
    <div>
      <Swiper
        ref={swiperRef}
        spaceBetween={16}
        grabCursor={true}
        allowTouchMove={true}
        slidesPerView={'auto'}
      >
        <SwiperSlide style={{ width: 7 }} />

        {categories.map((category) => (
          <SwiperSlide
            key={category?.id}
            onClick={() => {
              setSelected(category?.name);
              onSelecteCategory(category);
            }}
            style={{
              width: 250, height: 60, display: 'flex', alignItems: 'center'
            }}
          >
            <div
              className={`${styles.button} ${selected === category?.name && styles.buttonSelected}`}
            >
              {category?.name}
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide style={{ width: 7 }} />
      </Swiper>
    </div>
  );
};


interface CardsProps {
  categories: Category[],
  onSlideNext: () => void
  isLoading: boolean
  selectedCategory: number
}

const Cards: FC<CardsProps> = ({ categories, isLoading, selectedCategory }) => {
  const swiperRef = useRef<SwiperRef>(null);
  if (isLoading) {
    return (
      <ul className={styles.skeletons}>
        <Skeleton
          variant="rounded"
          className={styles.card}
          sx={{ backgroundColor: 'var(--bg-skeleton-50)' }}
        />
        <Skeleton
          variant="rounded"
          className={styles.card}
          sx={{ backgroundColor: 'var(--bg-skeleton-50)' }}
        />
        <Skeleton
          variant="rounded"
          className={styles.card}
          sx={{ backgroundColor: 'var(--bg-skeleton-50)' }}
        />
      </ul>
    );
  }
  return (
    <div>
      <Swiper
        ref={swiperRef}
        spaceBetween={20}
        grabCursor={true}
        allowTouchMove={true}
        slidesPerView={'auto'}
      >
        <SwiperSlide style={{ width: 4 }} />
        {categories?.find((category) => category.id === selectedCategory)?.quizzes
          .map((item) => (
            <SwiperSlide
              key={item.id}
              className={styles.card}
            >
              <div>
                <img src={item?.customImage ?? 'https://placehold.co/400'} alt={item?.name} />

                <article className={styles.content}>
                  <h4>{item?.name}</h4>

                  <p>{item?.name}</p>

                  <div>
                    <span>{item?.name}</span>
                    <span>{item?.name}</span>
                  </div>
                </article>
              </div>
            </SwiperSlide>
          ))}
        <SwiperSlide style={{ width: 4 }} />
      </Swiper>
    </div>
  );
};
