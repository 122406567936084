import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReligionyfyTypes } from '../../../../../models/QuizGptGenerators';
// assets
import styles from './cardsListChoices.module.scss';
import quizIcon from '../../../../../assets/religionify/Quiz.png';
import lessonIcon from '../../../../../assets/religionify/Storytelling.png';
import chatIcon from '../../../../../assets/religionify/Chat.png';
// swiper
import { SwiperSlide, Swiper } from 'swiper/react';
import { useQuizGPT } from '../../quizGPT.hooks';


type Cards = {
  img: string,
  name: ReligionyfyTypes
}

const CARDCHOICES: Cards[] = [
  {
    img: chatIcon,
    name: ReligionyfyTypes.CHAT
  },
  {
    img: lessonIcon,
    name: ReligionyfyTypes.LESSON
  },
  {
    img: quizIcon,
    name: ReligionyfyTypes.QUIZ
  }
];


export const CardsListChoices: FC = () => {
  const { setSelectedQuizGen, selectedQuizGen, quizGptSettings: { darkMode } } = useQuizGPT();
  const { t } = useTranslation();

  const handleCardClick = (quizGen: ReligionyfyTypes): void => {
    setSelectedQuizGen(quizGen);
  };

  return (
    <div className={styles.cardsList}>
      <h3>{t('quizGenerators.title')}</h3>

      <div className={styles.cardsContainer}>
        <Swiper
          className={styles.swiper}
          slidesPerView={'auto'}
          allowTouchMove={true}
          breakpoints={{
            600: {
              slidesPerView: 3,
              allowTouchMove: false
            }
          }}
          spaceBetween={20}
        >
          {CARDCHOICES.map((card, i) => (
            <SwiperSlide key={i} className={styles.swiperSlide}>
              <div
                key={card.name}
                onClick={() => handleCardClick(card.name)}
                className={`${styles.card} ${darkMode && styles.cardDark}`}
                style={{
                  backgroundColor: card.name === selectedQuizGen ? 'var(--selectedCard)' : '',
                  borderColor: card.name === selectedQuizGen ? 'var(--selectedCardBorder)' : '',
                }}
              >
                <img src={card.img} alt={card.name} />

                <span>
                  {t(`quizGenerators.cards.${i}`)}
                </span>
              </div>
            </SwiperSlide>
          ))}
          <SwiperSlide className={styles.swiperSlide}>
            <div className={styles.ghostSlide} />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
