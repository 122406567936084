import { Dispatch, SetStateAction, createContext } from 'react';
import { DataResponse, HistoryObjI } from '../../../models/QuizGTPResponse';
import { PayloadReq, QuizGPTCourseReq, QuizGPTLessonPlusQuizReq, QuizGPTQuizOnlyReq } from '../../../models/QuizGPTRequest';
import { InputTypes } from './components/interfaces';
import SwiperCore from 'swiper';
import { QuizGptSettings } from '../../../models/QuizGptSettings';
import { ReligionyfyTypes } from '../../../models/QuizGptGenerators';


class QuizGPT {
  data!: DataResponse;
  payload!: PayloadReq;
  quizTopic!: string;
  setQuizTopic!: Dispatch<SetStateAction<string>>;
  generateOnlyQuiz!: (payload: QuizGPTQuizOnlyReq, type: InputTypes) => Promise<void>;
  generateLesson!: (payload: QuizGPTLessonPlusQuizReq) => Promise<void>;
  generateChat!: (payload: QuizGPTCourseReq) => Promise<void>;
  getLessonByQuizId!: (quizId: number) => Promise<void>;
  getCourseById!: (courseId: number) => Promise<void>;
  history!: HistoryObjI[];
  setHistory!: Dispatch<SetStateAction<HistoryObjI[]>>;
  getHistory!: () => void;
  deleteHistoryObj!: (id: number) => void;
  isLoading!: boolean;
  hasLoaded!: boolean;
  error!: string;
  outerSwiper!: SwiperCore;

  showSettingModal!: boolean;
  setShowSettingModal!: Dispatch<SetStateAction<boolean>>;
  showLoginModal!: boolean;
  setShowLoginModal!: Dispatch<SetStateAction<boolean>>;
  showSupportModal!: boolean;
  setShowSupportModal!: Dispatch<SetStateAction<boolean>>;
  setUser!: Dispatch<SetStateAction<any>>;
  user!: any;
  shouldShowLoginModal!: () => boolean;
  setOuterSwiper!: Dispatch<SetStateAction<SwiperCore>>;
  showSnapBookSlide!: boolean;
  setShowSnapBookSlide!: Dispatch<SetStateAction<boolean>>;

  quizGptSettings!: QuizGptSettings;
  setQuizGptSettings!: Dispatch<SetStateAction<QuizGptSettings>>;
  saveQuizGptSettings!: (values: QuizGptSettings) => void;

  selectedQuizGen!: ReligionyfyTypes;
  setSelectedQuizGen!: Dispatch<SetStateAction<ReligionyfyTypes>>;
  resetForm!: () => void;

  activeDots!: boolean;
  quizIdLoading!: number;

  accessToken!: string;
  setAccessToken!: Dispatch<SetStateAction<string>>;

  navbar!: {
    isOnTop: boolean,
    setIsOnTop: Dispatch<SetStateAction<boolean>>
  };
}

export const QuizGPTContext = createContext(new QuizGPT());
