import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './support.module.scss';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modal/Modal';
import { Button } from '../../../button/Button';


interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

export const SupportModal: FC<Props> = ({ onToggle, isOpen }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onToggle}>
      <div className={styles.form} onClick={(e) => e.stopPropagation()}>
        <button
          type="button"
          onClick={onToggle}
          className={styles.closeButton}
          aria-label="close"
        >
          <FontAwesomeIcon
            className={styles.closeIcon}
            size="lg"
            fontWeight={900}
            icon={faXmark}
          />
        </button>

        <figure
          className={styles.imgContainer}
        />

        <div className={styles.content}>
          <div className={styles.header}>
            <h3 className={styles.heading}>
              {t('supportModal.title')}
            </h3>
            <p>
              {t('supportModal.description.0')}
            </p>
            <p>
              {t('supportModal.description.1')}
            </p>
          </div>

          <Button className={styles.button} color="secondary" type="button" >
            {t('supportModal.button')}
          </Button>

          <div className={styles.footer}>
            <p>
              {t('supportModal.description.2')}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
