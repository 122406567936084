import { FC, RefObject } from 'react';
import LottiePLayer from 'lottie-react';

// swiper
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

// assets
import styles from './loadingResult.module.scss';
import animStep1 from '../../../../../assets/JSONs Loading Bar/paso-n1.json';
import animStep2 from '../../../../../assets/JSONs Loading Bar/paso-n2.json';
import animStep3 from '../../../../../assets/JSONs Loading Bar/paso-n3.json';
import animStep4 from '../../../../../assets/JSONs Loading Bar/paso-n4.json';
import animStep5 from '../../../../../assets/JSONs Loading Bar/paso-n5.json';
import animStep6 from '../../../../../assets/JSONs Loading Bar/paso-n6.json';
import { useTranslation } from 'react-i18next';



const STEPSANIMATIONS = [animStep1, animStep2, animStep3, animStep4, animStep5, animStep6];


interface Props {
  swiperRef: RefObject<SwiperRef>
}
export const StepsSlidesAnimations: FC<Props> = ({ swiperRef }) => {

  const { t } = useTranslation();

  return (
    <div style={{ maxWidth: '50rem' }}>
      <Swiper
        ref={swiperRef}
        style={{ maxWidth: '50rem' }}
        effect="fade"
        allowTouchMove={false}
        allowSlidePrev={false}
        fadeEffect={{ crossFade: true }}
        modules={[EffectFade]}>

        {STEPSANIMATIONS.map((lottie, i) => (
          <SwiperSlide key={i}>
            <figure className={styles.figure}>
              <LottiePLayer
                className={styles.lottie}
                animationData={lottie}
                loop={true}
              />
              <article>
                <h3>{t(`results.${i}.title`)}</h3>

                <p>{t(`results.${i}.message`)}</p>
              </article>
            </figure>
          </SwiperSlide>
        ))}

      </Swiper>
    </div>
  );
};
